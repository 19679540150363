import React, {Component} from "react";
import {Col, Row, Table} from "react-bootstrap";
import ReceiptExchange from "./ReceiptExchange";
import ReceiptExchangeRemove from "./ReceiptExchangeRemove";

export default class ReceiptExchangeList extends Component {

    state = {
        exchanges: []
    }

    refreshExchanges = () => {
        fetch('/rest/exchanges').then(res => res.json()).then((result) => {
            this.setState({exchanges: result});
        });
    }

    componentWillMount() {
        this.refreshExchanges();
    }

    modalHandler = (exchange) => {
        if (this.state.exchangeModal != null) {
            this.setState({
                exchangeModal: undefined
            })
        } else {
            this.setState({
                exchangeModal: (<ReceiptExchange onClose={this.modalHandler} onChange={this.refreshExchanges} exchange={exchange}/>)
            })
        }
    }

    deleteHandler = (exchange) => {
        if (this.state.exchangeModal != null) {
            this.setState({
                exchangeModal: undefined
            })
        } else {
            this.setState({
                exchangeModal: (<ReceiptExchangeRemove onClose={this.modalHandler} onChange={this.refreshExchanges} exchange={exchange}/>)
            })
        }
    }

    render() {

        return (
            <div className={'container-sm mt-3'}>
                {this.state.exchangeModal || ''}
                <Row>
                    <Col lg={12} className={'text-end'}>
                        <button className={'btn btn-primary'} onClick={() => this.modalHandler(null)}>Нэмэх</button>
                    </Col>
                </Row>
                <Table striped className={'table w-100 small'}>
                    <thead>
                    <tr>
                        <th width={80}>Order</th>
                        <th>Нэр</th>
                        <th width={200}>Code</th>
                        <th width={200}>Төрөл</th>
                        <th width={100}>Status</th>
                        <th width={100}>Time Out<br/>/mills/</th>
                        <th>URL</th>
                        <th width={150}>Засах</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.exchanges.map(exchange => (
                        <tr key={exchange.order}>
                            <td className={'align-middle'}>{exchange.order}</td>
                            <td className={'align-middle'}>{exchange.name}</td>
                            <td className={'align-middle'}>{exchange.code}</td>
                            <td className={'align-middle'}>{exchange.type}</td>
                            <td className={'align-middle'}>{exchange.status}</td>
                            <td className={'align-middle'}>{exchange.timeOut}</td>
                            <td className={'align-middle'}>{exchange.exchangeUrl}</td>
                            <td className={'align-middle'}>
                                <button className={'btn btn-primary btn-sm'} style={{marginRight: '5px'}}
                                        onClick={() => this.modalHandler(exchange)}>Засах
                                </button>
                                <button className={'btn btn-danger btn-sm'}
                                        onClick={() => this.deleteHandler(exchange)}>Устгах
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}