import React, {Component} from "react";
import {Accordion, Badge, Col, ListGroup, Row} from "react-bootstrap";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import AccordionItem from "react-bootstrap/AccordionItem";

export default class PosInfo extends Component {

    state = {
        pos: {
            appInfo: {},
            merchants: []
        }
    }

    sendData = () => {
        fetch('/rest/sendData').then((result) => {
            this.refreshInfo();
        });
    }

    refreshInfo = () => {
        fetch('/rest/info').then(res => res.json()).then((result) => {
            this.setState({
                pos: result
            });
        });
    }

    componentWillMount() {
        this.refreshInfo();
    }

    render() {
        return (
            <div className={'container-sm mt-3'}>
                <div className={'card'}>
                    <div className={'card-header'}>
                        <div className={'row'}>
                            <div className={'col-3'}>
                                <span className={'h4'}>Ерөнхий мэдээлэл</span>
                            </div>
                            <div className={'col-9 text-end'}>
                                <button type={'button'} className={'btn btn-secondary'} onClick={this.sendData}>Мэдээлэл илгээх</button>
                            </div>
                        </div>
                    </div>
                    <div className={'card-body'}>
                        <div className={'row'}>
                            <label className="form-label col h6">ПОС-н мэдээлэл:</label>
                        </div>
                        <div className={'row mb-2'}>
                            <label className="form-label col-sm-1 col-form-label-sm">Id:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.posId || ''} readOnly={true}/>
                            </div>
                            <label className="form-label col-sm-1 col-form-label-sm">Дугаар:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.posNo || ''} readOnly={true}/>
                            </div>
                            <label className="form-label col-sm-1 col-form-label-sm">Хувилбар:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.version || ''} readOnly={true}/>
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <label className="form-label col h6">Оператор байгууллага:</label>
                        </div>
                        <div className={'row mb-2'}>
                            <label className="form-label col-sm-2 col-form-label-sm">Татвар төлөгчийн дугаар:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.operatorTIN || ''} readOnly={true}/>
                            </div>
                            <label className="form-label col-sm-2 col-form-label-sm">Операторын нэр:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.operatorName || ''} readOnly={true}/>
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <label className="form-label col h6">Процессийн мэдэлээлэл:</label>
                        </div>
                        <div className={'row mb-2'}>
                            <label className="form-label col-2 col-form-label-sm">Нийт сугалаа:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.leftLotteries || ''} readOnly={true}/>
                            </div>
                            <label className="form-label col-2 col-form-label-sm">Сүүлд илгээсэн огноо:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.lastSentDate || ''} readOnly={true}/>
                            </div>
                            <label className="form-label col-1 col-form-label-sm">Database:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.appInfo['database-host'] || ''} readOnly={true}/>
                            </div>
                        </div>
                        <div className={'row mb-2'}>
                            <label className="form-label col-2 col-form-label-sm">Ажиллын хавтас:</label>
                            <div className={'col'}>
                                <input type="text" className="form-control form-control-sm" value={this.state.pos.appInfo.workDir || ''} readOnly={true}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'card mt-3'}>
                    <div className={'card-header'}>Мерчантын жагсаалт</div>
                    <div className={'card-body'}>
                        <Accordion>
                            {this.state.pos.merchants.map(merchant => (
                                <AccordionItem eventKey={'mc_' + merchant.tin} key={'mk_' + merchant.tin}>
                                    <AccordionHeader>
                                        <Row style={{width: '100%'}}>
                                            <Col sm={2}>{merchant.tin}</Col>
                                            <Col sm={9}>
                                                <span style={{marginRight: '10px'}}>{merchant.name}</span>
                                                <sup style={{fontSize:'15px'}}>
                                                    <Badge bg="primary" pill>{merchant.customers.length}</Badge>
                                                </sup>
                                            </Col>
                                        </Row>
                                    </AccordionHeader>
                                    <AccordionBody>
                                        <ListGroup variant={"flush"}>
                                            {merchant.customers.map(customer => (
                                                <ListGroup.Item eventKey={'cc_' + customer.tin} key={'ck_' + customer.tin}>
                                                    <Row>
                                                        <Col sm={2}>{customer.tin}</Col>
                                                        <Col sm={10}>
                                                            <span style={{marginRight: '5px'}}>{customer.name}</span>
                                                            <sup style={{fontSize:'13px',}}>
                                                                <Badge className={"small"} bg={customer.vatPayer ? "success" : "danger"} pill>
                                                                    {customer.vatPayer ? 'НӨАТ суутган төлөгч мөн' : 'НӨАТ суутган төлөгч биш'}
                                                                </Badge>
                                                            </sup>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </AccordionBody>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}