import React, {Component} from "react";
import {Button, Col, Modal, Row, Table} from "react-bootstrap";

export default class InvalidReceiptList extends Component {

    state = {
        receipts: []
    }

    refreshExchanges = () => {
        fetch('/rest/receipt/invalid/list').then(res => res.json()).then((result) => {
            this.setState({receipts: result});
        });
    }

    componentWillMount() {
        this.refreshExchanges();
    }

    closeErrorModal = () => {
        this.setState({
            invalidReceiptSendingModal: null
        })
    }

    modalHandler = (receipt) => {
        if (receipt != null) {
            this.setState({
                invalidReceiptSendingModal: (
                    <Modal size={'lg'} show={true} centered>
                        <Modal.Header className={'fw-bold'}>ДДТД: {receipt.id}</Modal.Header>
                        <Modal.Body>Баримтын мэдээллийг илгээж байна...</Modal.Body>
                    </Modal>
                )
            })

            fetch('/rest/receipt/invalid/send/' + receipt.id).then(resp => {
                this.setState({
                    invalidReceiptSendingModal: null
                })
                if (resp.ok) return resp;
                throw resp
            }).then(()=>{
                this.refreshExchanges()
                this.setState({
                    invalidReceiptSendingModal: (
                        <Modal size={'lg'} show={true} centered>
                            <Modal.Header className={'fw-bold'}>ДДТД: {receipt.id}</Modal.Header>
                            <Modal.Body className={'text-success'}>Баримт амжилттай илгээгдлээ.</Modal.Body>
                            <Modal.Footer>
                                <Button variant={"danger"} onClick={() => this.closeErrorModal()}>Хаах</Button>
                            </Modal.Footer>
                        </Modal>
                    )
                })
            }).catch(resp => {
                resp.json().then(body => {
                    let message;
                    if (body != null) {
                        message = body.message;
                    } else {
                        message = resp.statusText;
                    }
                    this.setState({
                        invalidReceiptSendingModal: (
                            <Modal size={'lg'} show={true} centered>
                                <Modal.Header className={'fw-bold'}>ДДТД: {receipt.id}</Modal.Header>
                                <Modal.Body className={'text-danger'}>{message}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant={"danger"} onClick={() => this.closeErrorModal()}>Хаах</Button>
                                </Modal.Footer>
                            </Modal>
                        )
                    })
                });
            });
        }
    }

    render() {

        return (
            <div className={'container-sm mt-3'}>
                {this.state.invalidReceiptSendingModal || ''}
                <Row>
                    <Col lg={12} className={'text-end'}>
                        <button className={'btn btn-primary'} onClick={() => this.refreshExchanges()}>Шинэчлэх</button>
                    </Col>
                </Row>
                <Table striped className={'table w-100 small'}>
                    <thead>
                    <tr>
                        <th width={200}>ДДТД</th>
                        <th width={150}>ТТД</th>
                        <th width={200}>Огноо</th>
                        <th>Алдаа</th>
                        <th width={80}>Илгээх</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.receipts.map(receipt => (
                        <tr key={receipt.id}>
                            <td className={'align-middle'}>{receipt.id}</td>
                            <td className={'align-middle'}>{receipt.merchantTIN}</td>
                            <td className={'align-middle'}>{receipt.createdDate}</td>
                            <td className={'align-middle'}>{receipt.errorMsg}</td>
                            <td className={'align-middle'}>
                                <button className={'btn btn-primary btn-sm'} style={{marginRight: '5px'}}
                                        onClick={() => this.modalHandler(receipt)}>Илгээх
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}